<template>
    <div id="doctor-clinics">
        <div class="clinic" v-for="(clinic, index) in doctor.clinicList" :key="index">
            <h2 class="clinic-name"><router-link :to="'/hospital/detail/'+clinic.clinicid" v-text="clinic.clinicname"></router-link></h2>
            <p class="clinic-area" :title="`地區：${clinic.arealevel1name}-${clinic.arealevel2name}`" v-text="`${clinic.arealevel1name}-${clinic.arealevel2name}`"></p>
            <p class="clinic-tel" :title="`電話：${clinic.contacttel || '--'}${clinic.contacttelother ? `（${clinic.contacttelother}）` : ''}`" v-text="`${clinic.contacttel || '--'}${clinic.contacttelother ? `（${clinic.contacttelother}）` : ''}`"></p>
            <p class="clinic-email" :title="`電郵：${clinic.email || '--'}`" v-text="`${clinic.email || '--'}`"></p>
            <p class="clinic-address" :title="`詳細地址：${clinic.address}`" v-text="clinic.address"></p>
        </div>
    </div>
</template>

<script>
	export default {
        props: {
            doctor: undefined,
        },
        data(){
            return {
            }
        },
        mounted: function(){
        }
    }
</script>

<style lang="scss" scoped>

    #doctor-clinics {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    #doctor-clinics > .clinic {
        background-color: #fff;
        margin: 19px 16px 5px 16px;
        border-radius: 8px;
        box-shadow: 1px 2px 8px 0px #E8EAF3;
        display: flex;
        flex-direction: column;
        padding: 22px 20px 27px 20px;
    }

    #doctor-clinics > .clinic:last-child {
        margin-bottom: 20px;
    }

    #doctor-clinics > .clinic > p {
        display: inline-block;
    }

    #doctor-clinics > .clinic > .clinic-name {
        color: #242020;
        font-size: 20px;
        font-weight: bold;
        line-height: 20px;
    }

    #doctor-clinics > .clinic > .clinic-area {
        color: #969696;
        font-size: 16px;
        font-weight: 15px;
        font-weight: 400;
        margin-top: 11px;
    }

    #doctor-clinics > .clinic > .clinic-tel,
    #doctor-clinics > .clinic > .clinic-email,
    #doctor-clinics > .clinic > .clinic-address {
        color: #242020;
        font-size: 16px;
        line-height: 15px;
        font-weight: 400;
        padding-left: 35px;
        position: relative;
    }

    #doctor-clinics > .clinic > .clinic-tel {
        margin-top: 36px;
    }

    #doctor-clinics > .clinic > .clinic-email {
        margin-top: 16px;
    }

    #doctor-clinics > .clinic > .clinic-address {
        margin-top: 17px;
    }

    #doctor-clinics > .clinic > .clinic-tel::after,
    #doctor-clinics > .clinic > .clinic-email::after,
    #doctor-clinics > .clinic > .clinic-address::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 14px;
        height: 14px;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-image: url(../../../assets/imgs/pc/img_iphone@2x.png);
    }

    #doctor-clinics > .clinic > .clinic-email::after {
        background-image: url(../../../assets/imgs/pc/img_youjian@2x.png);
        width: 14px;
        height: 11px;
        top: 4px;
    }

    #doctor-clinics > .clinic > .clinic-address::after {
        background-image: url(../../../assets/imgs/pc/img_dib@2x.png);
        width: 11px;
        height: 14px;
    }
</style>
